import { Organization } from "@squeeze-io/database";
import { DefaultUser } from "next-auth";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { Role } from "@squeeze-io/database";
import { UserOrganization } from "@/lib/permissions";

export interface SessionUser extends DefaultUser {
  role?: Role;
  provider?: string | null;
}

type State = {
  user: SessionUser | {};
  currentOrg: UserOrganization | {};
  initializedCurrentOrg: boolean;
  initializedUser: boolean;
  loadingData: boolean;
};

export interface UserSlice extends State {
  getSessionState: () => {
    user: SessionUser | {};
    currentOrg: UserOrganization | {};
    initializedCurrentOrg: boolean;
  };
  getCurrentOrgSlug: () => string | undefined;
  isSuperAdmin: () => boolean;
  setCurrentUser: (user: SessionUser) => void;
  setCurrentOrg: (currentOrg: Organization) => void;
  setLoadingData: (loading: boolean) => void;
}

export const INITIAL_STATE: State = {
  user: {},
  currentOrg: {},
  initializedCurrentOrg: false,
  initializedUser: false,
  loadingData: false,
};
export const SWITCHER_RESET_STATE = {
  currentOrg: {},
  initializedCurrentOrg: false,
  loadingData: false,
};

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set,
  get
) => {
  sliceResetFns.add(() => set(SWITCHER_RESET_STATE));
  return {
    ...INITIAL_STATE,
    getSessionState: () => {
      const { user, currentOrg, initializedCurrentOrg } = get();
      return { user, currentOrg, initializedCurrentOrg };
    },
    getCurrentOrgSlug: () => (get().currentOrg as UserOrganization).slug,
    isSuperAdmin: () => {
      const role = (get().user as SessionUser).role;
      return role === Role.SUPERADMIN;
    },
    setCurrentUser: (user) => set({ user }),
    setLoadingData: (loading) => set({ loadingData: loading }),
    setCurrentOrg: (currentOrg) => set({ currentOrg }),
  };
};

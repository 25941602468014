import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { slugify } from "@ui/lib/orgUtils";
import {
  ChartOutputData,
  SingleCategoryChartOutputData,
} from "@/lib/charts/reviewDataTransformers";

interface State {
  competitorLeaderBoard: {
    [competitorSlug: string]: ChartOutputData | null;
  } | null;
  initializedCompetitorLeaderBoard: boolean;
}

export interface CompetitorLeaderBoardSlice extends State {
  getCompetitorLeaderBoard: (competitorName?: string) => {
    [category: string]: SingleCategoryChartOutputData;
  } | null;
  setCompetitorLeaderBoard: (
    competitorLeaderBoard: {
      [competitorSlug: string]: {
        [category: string]: SingleCategoryChartOutputData;
      };
    } | null
  ) => void;
}

export const INITIAL_STATE: State = {
  competitorLeaderBoard: null,
  initializedCompetitorLeaderBoard: false,
};

export const createCompetitorLeaderBoardSlice: StateCreator<
  CompetitorLeaderBoardSlice,
  [],
  [],
  CompetitorLeaderBoardSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    getCompetitorLeaderBoard: (competitorName: string = "") => {
      const leaderBoard = get().competitorLeaderBoard;
      if (!leaderBoard || !competitorName || competitorName === "") return null;
      return leaderBoard[slugify(competitorName)];
    },
    setCompetitorLeaderBoard: (data) =>
      set({
        competitorLeaderBoard: data,
        initializedCompetitorLeaderBoard: true,
      }),
  };
};

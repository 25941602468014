import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

const chartTypes = ["Followers", "Impressions"];

export interface State {
  selectedChartType: string;
  chartTypes: string[];
}

export const INITIAL_STATE: State = {
  selectedChartType: chartTypes[0],
  chartTypes: chartTypes,
};

export interface LinkedInSlice extends State {
  setSelectedChartType: (chartType: string) => void;
}

export const createLinkedInSlice: StateCreator<
  LinkedInSlice,
  [],
  [],
  LinkedInSlice
> = (set) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setSelectedChartType: (data) => set({ selectedChartType: data }),
  };
};
